import { HttpErrorResponse, HttpEvent, HttpHandlerFn, HttpInterceptorFn, HttpRequest } from "@angular/common/http";
import { catchError, switchMap } from "rxjs/operators";
import { inject } from "@angular/core";
import { ActionSnackService, isJwtTokenExpiringIn, retrieveSessionToken, storeSessionToken, UpgradeService } from "common";
import { from, Observable, of, throwError } from "rxjs";
import { Duration, Instant } from "@js-joda/core";
import { LoginEndpoint } from "apina-frontend";

const RENEW_BEFORE_EXPIRATION_TIME = Duration.ofHours(6);
const MIN_RENEW_PERIOD = Duration.ofMinutes(5);

let loginSnackShown = false;
let lastRenewalTime: Instant | null = null;

export const tokenInterceptor: HttpInterceptorFn = (req, next) => {
    const actionSnackService = inject(ActionSnackService);
    const upgradeService = inject(UpgradeService);
    const loginEndpoint = inject(LoginEndpoint);

    const isRenewing = req.url.includes("/api/login/renew-session-token");
    if (!isRenewing && needsTokenRenewal()) {
        console.info(`session token is expiring in ${RENEW_BEFORE_EXPIRATION_TIME}, renewing automatically`);
        lastRenewalTime = Instant.now();

        return from(loginEndpoint.renewSessionToken()).pipe(
            catchError(e => {
                console.error("failed to renew session token", e);
                return of(null);
            }),
            switchMap(token => {
                if (token != null) {
                    console.info("store renewed session token", token);
                    storeSessionToken(token);
                }
                return handleRealRequest(req, next, actionSnackService, upgradeService);
            }));
    } else {
        return handleRealRequest(req, next, actionSnackService, upgradeService);
    }
};

function needsTokenRenewal(): boolean {
    const authToken = retrieveSessionToken();
    if (authToken === null)
        return false;

    if (lastRenewalTime != null && Duration.between(lastRenewalTime, Instant.now()).compareTo(MIN_RENEW_PERIOD) < 0)
        return false;

    return isJwtTokenExpiringIn(authToken, RENEW_BEFORE_EXPIRATION_TIME);
}

function handleRealRequest(
    req: HttpRequest<unknown>,
    next: HttpHandlerFn,
    actionSnackService: ActionSnackService,
    upgradeService: UpgradeService,
): Observable<HttpEvent<unknown>> {
    const authToken = retrieveSessionToken();
    const request = (authToken != null)
        ? req.clone({headers: req.headers.set("Authorization", "Bearer " + authToken)})
        : req;

    return next(request).pipe(
        catchError((error: HttpErrorResponse) => {
            if (error.status === 401 && !loginSnackShown) {
                loginSnackShown = true;

                actionSnackService.showSnack({
                    message: "Istuntosi on vanhentunut",
                    actionText: "Päivitä istunto",
                    action() {
                        storeSessionToken(null);
                        upgradeService.upgradeNow();
                    }
                });
            }

            return throwError(() => error);
        }));
}
