import { inject, Injectable, resource } from "@angular/core";
import { merge, timer } from "rxjs";
import { MainMenuEndpoint } from "apina-frontend";
import { RxStompService } from "common";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";

// Timed reloads are not strictly necessary because of the topic, but's lets do them anyway
const RELOAD_MILLIS = 15 * 60 * 1000;

/**
 * Contains the data for main menu so that it need not be refetched every time the component
 * is recreated.
 */
@Injectable({providedIn: "root"})
export class MainMenuService {

    private readonly mainMenuEndpoint = inject(MainMenuEndpoint);
    private readonly rxStompService = inject(RxStompService);
    readonly latestData = resource({
        loader: () => this.mainMenuEndpoint.loadMainMenuData()
    });

    constructor() {
        merge(timer(0, RELOAD_MILLIS), this.rxStompService.watch("/topic/main-menu-data")).pipe(takeUntilDestroyed())
            .subscribe(() => this.reload());
    }

    reload(): void {
        this.latestData.reload();
    }
}
