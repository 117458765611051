import { ChangeDetectionStrategy, Component, computed, Inject, signal, Signal } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from "@angular/material/dialog";
import { AreaResponsibilitiesEndpoint, AreaResponsibilityInfo } from "apina-frontend";
import { controlValuesSignal, ErrorService, PilotageAreaId, SimplifyUsernamePipe, SpinnerButtonComponent } from "common";
import { FormControl, ReactiveFormsModule } from "@angular/forms";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatButtonModule } from "@angular/material/button";

@Component({
    templateUrl: './area-responsibility.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        MatButtonModule,
        MatCheckboxModule,
        MatDialogModule,
        ReactiveFormsModule,
        SimplifyUsernamePipe,
        SpinnerButtonComponent,
    ]
})
export class AreaResponsibilityComponent {

    readonly areas: PilotageAreaSelection[];
    readonly saving = signal(false);
    readonly disabled: Signal<boolean>;

    constructor(
        private readonly areaResponsibilitiesEndpoint: AreaResponsibilitiesEndpoint,
        private readonly errorService: ErrorService,
        @Inject(MAT_DIALOG_DATA) readonly params: AreaResponsibilityComponentParams,
        private readonly dialogRef: MatDialogRef<AreaResponsibilityComponent>
    ) {

        this.areas = params.responsibility.areas.map(it => ({
            id: it.id,
            name: it.name,
            formControl: new FormControl<boolean>(true, {nonNullable: true})
        }));

        const selections = this.areas.map(it => controlValuesSignal(it.formControl));

        this.disabled = computed(() => selections.every(it => !it()));
    }

    private get selectedAreas(): PilotageAreaId[] {
        return this.areas.filter(it => it.formControl.value).map(it => it.id);
    }

    takeResponsibility(): void {
        this.saving.set(true);

        this.areaResponsibilitiesEndpoint.takeResponsibility(this.selectedAreas).then(
            () => this.dialogRef.close(true),
            e => {
                this.saving.set(false);
                this.errorService.showUpdateError(e);
            }
        );
    }

    releaseResponsibility(): void {
        this.saving.set(true);

        this.areaResponsibilitiesEndpoint.releaseResponsibility(this.selectedAreas).then(
            () => this.dialogRef.close(true),
            e => {
                this.saving.set(false);
                this.errorService.showUpdateError(e);
            }
        );
    }
}

interface PilotageAreaSelection {
    id: PilotageAreaId;
    name: string;
    formControl: FormControl<boolean>;
}

export interface AreaResponsibilityComponentParams {
    responsibility: AreaResponsibilityInfo;
}
