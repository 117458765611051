import { Injectable, Provider } from "@angular/core";
import { defer, Observable, timer } from "rxjs";
import { catchError, map, shareReplay, switchMap } from "rxjs/operators";
import { CameraInfo, MapDataProvider, PilotageInfo, PointOfInterestInfo, provideMapData, StationFenceInfo } from "maps";
import { CurrentAisData, MapEndpoint } from "apina-frontend";
import { Instant } from "@js-joda/core";
import { buildAuthenticatedUrl } from "common";

@Injectable()
class PilotwebMapDataProvider extends MapDataProvider {

    readonly pilotages$: Observable<PilotageInfo[]>;
    readonly cameras$: Observable<CameraInfo[]>;

    constructor(private readonly mapEndpoint: MapEndpoint) {
        super();

        this.pilotages$ = timer(0, 60_000).pipe(
            switchMap(() => defer(() => mapEndpoint.getTrafficInfo()).pipe(
                map(pilotages => pilotages.map(it => ({
                    state: it.state,
                    inVesselNoticeState: it.inVesselNoticeState,
                    vesselName: it.vessel.name,
                    vesselMmsi: it.vessel.mmsi,
                    startCode: it.routeStart.code,
                    endCode: it.routeEnd.code,
                    startTime: it.startTime,
                    endTime: it.endTime
                }))),
                catchError(e => {
                    console.log("error", e);
                    return [];
                }))));

        this.cameras$ = timer(0, 60_000).pipe(
            switchMap(() => defer(() => this.mapEndpoint.findCameras()).pipe(
                catchError(e => {
                    console.log("error", e);
                    return [];
                }))),
            shareReplay(1));
    }

    cameraUrl(cameraId: string, lastUpdated: Instant | null): string {
        // Add timestamp parameter to URL to force browser reload. Server ignores the parameter
        const params = (lastUpdated != null) ? {t: lastUpdated} : {};
        return buildAuthenticatedUrl(`/api/map/cameras/${cameraId}.jpg`,params);
    }

    findStationFences(): Promise<StationFenceInfo[]> {
        return this.mapEndpoint.findStationFences();
    }

    getCurrentAisData(): Promise<CurrentAisData> {
        return this.mapEndpoint.getCurrentAisData();
    }

    findPointsOfInterest(): Promise<PointOfInterestInfo[]> {
        return this.mapEndpoint.findPointsOfInterest();
    }
}

export function provideFrontendMapData(): Provider {
    return provideMapData(PilotwebMapDataProvider);
}
