import { ChangeDetectionStrategy, Component, Optional, signal } from "@angular/core";
import { FeedbackEndpoint } from "apina-frontend";
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from "@angular/forms";
import { MatDialogModule, MatDialogRef } from "@angular/material/dialog";
import { MatButtonModule } from "@angular/material/button";

@Component({
    templateUrl: './feedback.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        MatButtonModule,
        MatDialogModule,
        ReactiveFormsModule
    ]
})
export class FeedbackComponent {

    readonly form = new FormGroup({
        message: new FormControl("", {nonNullable: true, validators: Validators.required})
    });

    readonly saving = signal(false);
    readonly error = signal(false);

    constructor(
        private feedbackEndpoint: FeedbackEndpoint,
        @Optional() private readonly dialogRef?: MatDialogRef<FeedbackComponent>) {
    }

    send(): void {
        this.error.set(false);
        this.saving.set(true);

        this.feedbackEndpoint.sendFeedback({message: this.form.controls.message.value}).then(
            () => {
                if (this.dialogRef != null)
                    this.dialogRef.close();
                else
                    window.close();
            },
            error => {
                console.log(error);
                this.saving.set(false);
                this.error.set(true);
            }
        );
    }
}
