import { AugmentedReferenceData, ReferenceDataProvider } from "common";
import { inject } from "@angular/core";
import { PreloadedDataService } from "./preloaded-data.service";

export class PilotwebReferenceDataProvider extends ReferenceDataProvider {

    private readonly preloadedDataService = inject(PreloadedDataService);

    override async loadReferenceData(): Promise<AugmentedReferenceData> {
        await this.preloadedDataService.initialize();
        return this.preloadedDataService.referenceData;
    }
}
