import { ChangeDetectionStrategy, Component, signal } from "@angular/core";
import { ActivatedRoute, NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router, RouterOutlet } from "@angular/router";
import { JsonPipe } from "@angular/common";
import { Title } from "@angular/platform-browser";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { FrontendTranslations } from "./l10n/frontend.translations";
import { environment } from "../environments/environment";
import { MainMenuComponent } from "./menu/main-menu.component";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { decodeSessionTokenPayload, retrieveSessionToken } from "common";
import { FrontendEnvironmentService } from "./config/frontend-environment.service";

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        JsonPipe,
        RouterOutlet,
        MainMenuComponent,
        MatProgressSpinnerModule,
    ]
})
export class AppComponent {

    readonly menuSection = signal<string | null>(null);
    readonly loading = signal(false);
    readonly error = signal<NavigationError | null>(null);
    readonly showErrorDetails = !environment.production;

    constructor(
        router: Router,
        private readonly title: Title,
        frontendTranslateService: FrontendTranslations,
        frontendEnvironmentService: FrontendEnvironmentService,
    ) {
        initEnvironmentClasses(frontendEnvironmentService);

        router.events.pipe(takeUntilDestroyed()).subscribe(e => {
            if (e instanceof NavigationStart) {
                this.error.set(null);
                this.loading.set(true);
            } else if (e instanceof NavigationEnd || e instanceof NavigationCancel) {
                this.loading.set(false);
            } else if (e instanceof NavigationError) {
                console.error("navigation error", e);
                this.error.set(e);
                this.loading.set(false);
            }
        });

        frontendTranslateService.init("fi");
    }

    routeActivated(route: ActivatedRoute | null): void {
        this.menuSection.set(route?.snapshot?.data["menuSection"]);
    }

    routeDeactivated(): void {
        // Some pages customize the page title, so we need to reset the title when we move out of the page.
        this.title.setTitle("Pilotweb");
    }
}

function initEnvironmentClasses(environmentService: FrontendEnvironmentService): void {
    const token = retrieveSessionToken();
    const user = (token != null) ? decodeSessionTokenPayload(token) : null;

    if (user != null && user.type === "Internal" && isDeveloper(user.user.login) && environmentService.isProductionEnvironment()) {
        document.body.classList.add("border-[50px]");
        document.body.classList.add("border-[#d91e1e]");
    }
}

function isDeveloper(login: string): boolean {
    return login.includes("evident.fi");
}

